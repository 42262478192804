import React, { useEffect, useContext } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
import { GlobalDispatchContext } from "../context/GlobalContextProvider";
import { Faq } from "../components/partials/faq/faq";

const FaqPage = ({ data }) => {
  const { language, meta, faq } = data.strapi.faq;
  const { header, footer } = data.strapi.homepages[0];

  const dispatch = useContext(GlobalDispatchContext);
  useEffect(() => {
    dispatch({ type: "SET_LANGUAGE", language });
  });

  return (
    <Layout header={header} footer={footer}>
      <SEO title={meta.title} description={meta.description}/>
      {faq.map(({ heading, faqItem }, index) => (
        <Faq key={index} faqHeading={heading} faqItems={faqItem} />
      ))}
    </Layout>
  )
}

export default FaqPage

export const pageQuery = graphql`
  query FaqPage($id: ID!, $language: STRAPI_JSON!) {
    strapi {
      faq(id: $id) {
        language
        title
        faq {
          heading
          faqItem {
            question
            answer
          }
        }
        meta {
          title
          description
        }
      }
      homepages(where: $language) {
        header {
          logo {
            url
          }
          links {
            label
            slug
          }
        }
        footer {
          title
          copyright
          logo {
            url
          }
          nav {
            heading
            link {
              label
              slug
            }
          }
        }
      }
    }
  }
`
